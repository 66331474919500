import loadable from '@loadable/component'
import HeroSlider from '@cmp/sections/HeroSlider'
const Hero = loadable(() => import('../../components/sections/Hero'))
const RichText = loadable(() => import('../../components/sections/RichText'))
const Embed = loadable(() => import('../../components/sections/Embed'))
const Feature = loadable(() => import('../../components/sections/Feature'))
const System = loadable(() => import('../../components/sections/System'))
const FeaturedTextSlice = loadable(() =>
  import('../../components/sections/FeaturedTextSlice')
)
const SubpageHero = loadable(() =>
  import('../../components/sections/SubpageHero')
)
const BannerImage = loadable(() =>
  import('../../components/sections/BannerImage')
)
const CustomSlider = loadable(() =>
  import('../../components/sections/CustomSlider')
)
const FAQ = loadable(() => import('../../components/sections/FAQ'))
const PopularProdSlider = loadable(() =>
  import('../../components/sections/PopularProdSlider')
)
const AllBrands = loadable(() => import('../../components/sections/AllBrands'))

export default {
  PrismicBrandPageBody1FeaturedContent: {
    component: Feature,
    dataToProps(data) {
      const {
        primary: {
          text: { html },
          image,
          image: {
            thumbnails: { mobile }
          },
          img_position: imgPosition
        }
      } = data

      return {
        imageAlignment: imgPosition ? 'right' : 'left',
        html,
        image,
        mobile
      }
    }
  },
  PrismicBrandPageBodyFeaturedContent: {
    component: Feature,
    dataToProps(data) {
      const {
        primary: {
          text: { html },
          image,
          image: {
            thumbnails: { mobile }
          },
          img_position: imgPosition
        }
      } = data

      return {
        imageAlignment: imgPosition ? 'right' : 'left',
        html,
        image,
        mobile
      }
    }
  },
  PrismicPageBodyAllBrands: {
    component: AllBrands,
    dataToProps(data) {
      const {
        title: { text: title }
      } = data.primary

      const excludedBrandIds =
        data?.items?.map(item => item.excluded_brand_id) ?? []

      return { title, excludedBrandIds }
    }
  },
  PrismicPageBodyFaq: {
    component: FAQ,
    dataToProps(data) {
      const {
        heading: { text: heading },
        text: { text: text }
      } = data.primary

      const questions = data.items

      return { heading, text, questions }
    }
  },
  PrismicBrandPageBodyProductSlider: {
    component: PopularProdSlider,
    dataToProps(data) {
      const {
        heading: { text: heading }
      } = data.primary

      const products = data.items.map(item => item.productGroup).filter(Boolean)

      return { heading, products }
    }
  },
  PrismicPageBodyCustomSlider: {
    component: CustomSlider,
    dataToProps(data) {
      const {
        heading: { text: heading },
        tag: { text: tag }
      } = data.primary

      const products = data.items.map(item => item.productGroup).filter(Boolean)

      return { heading, tag, products }
    }
  },
  PrismicPageBodyBannerImage: {
    component: BannerImage,
    dataToProps(data) {
      const {
        alt,
        image,
        image: {
          thumbnails: { mobile }
        }
      } = data.primary

      return { image, mobile, alt }
    }
  },
  PrismicPageBodyRichText: {
    component: RichText,
    dataToProps(data) {
      return {
        html: data.primary.rich_text.html
      }
    }
  },

  PrismicPageBodySubpageHero: {
    component: SubpageHero,
    dataToProps(data) {
      const {
        titile: { text: title },
        text: { text }
      } = data.primary

      return { title, text }
    }
  },

  PrismicPageBodyFeaturedTextSlice: {
    component: FeaturedTextSlice,
    dataToProps(data) {
      const {
        heading: { text: heading },
        left_col: { html: leftCol },
        right_col: { html: rightCol }
      } = data.primary

      return {
        heading,
        leftCol,
        rightCol
      }
    }
  },

  PrismicPageBodyHeroSlider: {
    component: HeroSlider,
    dataToProps(data) {
      const slides = data.items.map(item => {
        const {
          link: { url: link },
          image: { url: image },
          image: {
            thumbnails: {
              mobile: { url: mobileImage }
            }
          }
        } = item

        return { link, image, mobileImage }
      })

      return {
        slides
      }
    }
  },

  PrismicPageBodyHeroImage: {
    component: Hero,
    dataToProps(data) {
      const {
        primary: {
          rich_text: { html: text },
          with_search_input: withSearchInput,
          heading: { text: heading }
        }
      } = data

      return {
        text,
        withSearchInput,
        heading
      }
    }
  },
  PrismicPageBodyFeature: {
    component: Feature,
    dataToProps(data) {
      const {
        primary: {
          text: { html },
          image,
          image: {
            thumbnails: { mobile }
          },
          img_position: imgPosition
        }
      } = data

      return {
        imageAlignment: imgPosition ? 'right' : 'left',
        html,
        image,
        mobile
      }
    }
  },

  PrismicPageBodyEmbed: {
    component: Embed,
    dataToProps(data) {
      return { html: data.primary.url.html }
    }
  },

  PrismicPageBodySystem: {
    component: System,
    dataToProps(data) {
      return {
        programName: data.primary.program,
        options: data.items
          .filter(({ parameter, value }) => parameter && value !== null)
          .reduce((acc, item) => {
            acc[item.parameter] = item.value
            return acc
          }, {})
      }
    }
  }
}
