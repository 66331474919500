import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import {
  usePreview,
  makeSlices,
  getPrismicPageVars
} from '@kogk/gatsby-theme-prismic'
import '@src/data/prismic-fragments/page'
import MainLayout from '@cmp/site/MainLayout'
import sliceMapping from '@src/data/prismic-slice-mapping/page-slices'
import { GlobalDataProvider } from '@src/data/global-data'
import { MenuTypeContext } from '@root/src/context/MenuTypeContext'
import { CartVisibility } from '@root/src/context/CartVisibility'
import { FooterVisibilityContext } from '@root/src/context/FooterVisibilityContext'
import { navigate } from '@reach/router'

const Slices = makeSlices(sliceMapping)

const Page = ({ data: staticData }) => {
  const data = usePreview(staticData)
  // For now, direct pages tagged with APÓTEKARINN to 404
  if (data.prismicPage.tags.includes('APÓTEKARINN')) {
    navigate('/404')
  }

  const { altUrls, language } = getPrismicPageVars(data.prismicPage)
  const { modifyVisibility } = useContext(FooterVisibilityContext)
  const { modifyCartVisibility } = useContext(CartVisibility)

  const { modify } = useContext(MenuTypeContext)
  useEffect(() => {
    modify('primary', '', data.prismicPage.tags.includes('DEFAULT_FRONTPAGE'))
    modifyVisibility(true)
    modifyCartVisibility(true)
  }, [])

  /**
   * Gatsby image is serving the correct image for each viewport so this is useless
   * unless we preload all possible versions which is overkill.
   */
  // const preloadedHeroImages = data.prismicPage.data.body.reduce((all, cur) => {
  //   if (cur.__typename === 'PrismicPageBodyHeroSlider') {
  //     all = all.concat(
  //       ...(cur.items ?? []).map(item => {
  //         const {
  //           image: { url: image },
  //           image: {
  //             thumbnails: {
  //               mobile: { url: mobileImage }
  //             }
  //           }
  //         } = item

  //         return [image, mobileImage]
  //       })
  //     )
  //   }

  //   return all
  // }, [])

  return (
    <>
      <GlobalDataProvider language={language} altUrls={altUrls}>
        <MainLayout>
          <article>
            <Slices body={data.prismicPage.data.body} />
          </article>
        </MainLayout>
      </GlobalDataProvider>
    </>
  )
}

export default Page

export const query = graphql`
  query genericPageQuery($prismicId: ID!) {
    prismicPage(prismicId: { eq: $prismicId }) {
      ...prismicPageFragmentFull
    }
  }
`
